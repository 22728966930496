import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { CurrentUserModel } from '../../../users/models/current-user/current-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthOwnerForNextLoginsGuard {
  constructor(private router: Router, private currentUserService: CurrentUserService) {}

  public canActivate(): Observable<true | UrlTree> {
    return this.currentUserService.getCurrentUser().pipe(
      take(1),
      map((currentUser: CurrentUserModel | null) => {
        if (currentUser?.showOwnerFirstLoginView) {
          return this.router.createUrlTree(['auth/activate-workshop-account']);
        }

        return true;
      })
    );
  }
}

/* eslint-disable @typescript-eslint/typedef */
import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthLoggedUserGuard } from './auth/guards/auth-logged-user/auth-logged-user.guard';
import { MainLayoutWithSidebarComponent } from './layout/pages/main-layout-with-sidebar/main-layout-with-sidebar.component';
import { AuthNotLoggedOrActivatedGuard } from './auth/guards/auth-not-logged-or-activated/auth-not-logged-or-activated.guard';
import { AuthOwnerForNextLoginsGuard } from './auth/guards/auth-owner-for-next-logins/auth-owner-for-next-logins.guard';
import { UserRoleMenuTabNameEnum } from './user-roles/enums/user-role-menu-tab-name/user-role-menu-tab-name.enum';
import { UserPermissionForMenuTabGuard } from './user-roles/guards/user-permission-for-menu-tab/user-permission-for-menu-tab.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule),
    canActivate: [(): Observable<boolean | UrlTree> => inject(AuthNotLoggedOrActivatedGuard).canActivate()],
  },
  {
    path: '',
    component: MainLayoutWithSidebarComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./dashboard/dashboard.module').then((module) => module.DashboardModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.dashboard)],
      },
      {
        path: 'contractors',
        loadChildren: () => import('./contractors/contractors.module').then((module) => module.ContractorsModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.contractors)],
      },
      {
        path: 'fixes',
        loadChildren: () => import('./fixes/fixes.module').then((module) => module.FixesModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.repairs)],
      },
      {
        path: 'recipes',
        loadChildren: () => import('./recipes/recipes.module').then((module) => module.RecipesModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.mixer)],
      },
      {
        path: 'primers',
        loadChildren: () => import('./primers/primers.module').then((module) => module.PrimersModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.primers)],
      },
      {
        path: 'clearcoats',
        loadChildren: () => import('./clearcoats/clearcoats.module').then((module) => module.ClearcoatsModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.clearcoats)],
      },
      {
        path: 'prices',
        loadChildren: () => import('./prices/prices.module').then((module) => module.PricesModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.priceList)],
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((module) => module.SettingsModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.settings)],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'workshop',
        loadChildren: () => import('./workshop/workshop.module').then((m) => m.WorkshopModule),
        canActivate: [
          (): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.workshopManagement),
        ],
      },
      {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule),
        canActivate: [(): UrlTree | boolean => inject(UserPermissionForMenuTabGuard).canActivate(UserRoleMenuTabNameEnum.statistics)],
      },
      {
        path: 'color-issues',
        loadChildren: () => import('./color-issues/color-issues.module').then((m) => m.ColorIssuesModule),
      },
      {
        path: 'changelog',
        loadChildren: () => import('./changelog/changelog.module').then((m) => m.ChangelogModule),
      },
    ],
    canActivate: [
      (): Observable<boolean | UrlTree> => inject(AuthLoggedUserGuard).canActivate(),
      (): Observable<boolean | UrlTree> => inject(AuthOwnerForNextLoginsGuard).canActivate(),
    ],
  },
  {
    path: 'mixes',
    loadChildren: () => import('./mixes/mixes.module').then((module) => module.MixesModule),
    canActivate: [
      (): Observable<boolean | UrlTree> => inject(AuthLoggedUserGuard).canActivate(),
      (): Observable<boolean | UrlTree> => inject(AuthOwnerForNextLoginsGuard).canActivate(),
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { forkJoin, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CurrentUserPermissionsService } from '../../../user-roles/services/current-user-permissions/current-user-permissions.service';
import { AuthUserService } from '../../../auth/services/auth-user/auth-user.service';
import { CurrentConnectionService } from '../../../connector/services/current-connection/current-connection.service';
import { CurrentLanguageService } from '../../../i18n/services/current-language/current-language.service';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { CurrentAdditionalSettingsService } from '../../../settings/services/current-additional-settings/current-additional-settings.service';

export const AppInitializerFactory = (
  currentLanguageService: CurrentLanguageService,
  currentSettingsService: CurrentSettingsService,
  currentAdditionalSettingsService: CurrentAdditionalSettingsService,
  authUserService: AuthUserService,
  currentConnectionService: CurrentConnectionService,
  currentUserPermissionsService: CurrentUserPermissionsService
): (() => Promise<void>) => {
  const initData$: Observable<void> = currentLanguageService.initCurrentLanguage().pipe(
    switchMap(() =>
      forkJoin([
        authUserService.initCurrentUser(),
        currentSettingsService.initSettings(),
        currentAdditionalSettingsService.initAdditionalSettings(),
        currentUserPermissionsService.initPermissions(),
      ])
    ),
    tap(() => {
      currentConnectionService.initCurrentConnectionService();
    }),
    map(() => void 0),
    catchError(() => of(void 0))
  );

  return (): Promise<void> => lastValueFrom(initData$);
};

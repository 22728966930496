import { Component, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';

import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';

import { ModalService } from './modal/services/modal.service';
import { SubscriptionStoreComponent } from './shared/components/subscription-store/subscription-store.component';
import { ConnectionQualityService } from './shared/services/connection-quality/connection-quality.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig],
})
export class AppComponent extends SubscriptionStoreComponent implements OnInit {
  constructor(
    private router: Router,
    private modalService: ModalService,
    protected ngbTooltipConfig: NgbTooltipConfig,
    private connectionQualityService: ConnectionQualityService
  ) {
    super();
    ngbTooltipConfig.container = 'body';
  }

  public ngOnInit(): void {
    this.initRouteChangeListener();
    this.initConnectionQualityListners();
  }

  private initRouteChangeListener(): void {
    this.subscription = this.router.events.pipe(filter((event: Event) => event instanceof NavigationStart)).subscribe((event: Event) => {
      if ((event as NavigationStart).navigationTrigger !== 'popstate') {
        return;
      }

      this.modalService.dismissAll();
    });
  }

  private initConnectionQualityListners(): void {
    this.subscription = this.connectionQualityService.initIsOnlineListner().subscribe();
    this.subscription = this.connectionQualityService.initConnectionSpeedListner().subscribe();
  }
}

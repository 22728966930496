import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthTokenService } from '../../services/auth-token/auth-token.service';

@Injectable()
export class AuthAccessTokenHeaderInterceptor implements HttpInterceptor {
  constructor(private authTokenService: AuthTokenService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken: string | null = this.authTokenService.getAccessToken();

    if (!accessToken || this.isLanguageRequestUrl(request)) {
      return next.handle(request);
    }

    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    );
  }

  private isLanguageRequestUrl(request: HttpRequest<unknown>): boolean {
    return request.url.includes('language/language');
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CurrentLanguageService } from '../../services/current-language/current-language.service';

@Injectable({
  providedIn: 'root',
})
export class ContentLanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private currentLanguageService: CurrentLanguageService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentLanguage: string = this.currentLanguageService.getCurrentLanguage();

    return next.handle(
      request.clone({
        setHeaders: {
          'Content-Language': currentLanguage,
        },
      })
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';

import { ApiErrorModel } from '../../models/api-error/api-error.model';
import { ApiErrorDto } from '../../dtos/api-error/api-error.dto';
import { ApiErrorCreator } from '../../creators/api-error/api-error.creator';
import { environment } from '../../../../environments/environment';
import { ApiError403TypeEnum } from '../../enums/api-error-403-type/api-error-403-type.enum';

export class ApiErrorsUtil {
  public static is401Error(httpErrorResponse: HttpErrorResponse): boolean {
    return httpErrorResponse.status === 401;
  }

  public static is403Error(httpErrorResponse: HttpErrorResponse): boolean {
    return httpErrorResponse.status === 403;
  }

  public static isBlocked(httpErrorResponse: HttpErrorResponse): boolean {
    const apiError: ApiErrorModel = httpErrorResponse.error;

    return apiError.type === ApiError403TypeEnum.blocked;
  }

  public static isForbidden(httpErrorResponse: HttpErrorResponse): boolean {
    const apiError: ApiErrorModel = httpErrorResponse.error;

    return apiError.type === ApiError403TypeEnum.forbidden;
  }

  public static getErrorFromHttpResponseError(httpErrorResponse: HttpErrorResponse): ApiErrorModel {
    const errorDto: ApiErrorDto = httpErrorResponse.error as ApiErrorDto;

    return ApiErrorCreator.create(errorDto, httpErrorResponse.status);
  }

  public static isConnectorError(httpErrorResponse: HttpErrorResponse): boolean {
    if (environment.connector.enabled && httpErrorResponse.url) {
      return httpErrorResponse.url.includes(environment.connector.url);
    }

    return false;
  }
}

import { Injectable } from '@angular/core';

import { SpeedTestService, SpeedTestSettingsModel } from 'ng-speed-test';
import { fromEvent, mergeMap, Observable, of, switchMap, takeWhile, timer } from 'rxjs';

import { ModalService } from '../../../modal/services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionQualityService {
  private readonly minInternetSpeedMbps: number;
  private readonly intervalForCheckInternetSpeedDurationMs: number;

  constructor(private speedTestService: SpeedTestService, private modalService: ModalService) {
    this.intervalForCheckInternetSpeedDurationMs = 1000 * 60 * 60;
    this.minInternetSpeedMbps = 0.5;
  }

  public initIsOnlineListner(): Observable<boolean> {
    return fromEvent(window, 'offline').pipe(switchMap(() => this.showNoConnectionModal()));
  }

  public initConnectionSpeedListner(): Observable<boolean> {
    return timer(0, this.intervalForCheckInternetSpeedDurationMs).pipe(
      mergeMap(() => this.speedTestService.getMbps(this.speedTestSettings)),
      switchMap((mbps: number) => this.checkInternetSpeed(mbps)),
      takeWhile((isDontShowAgainCheckboxChecked: boolean) => !isDontShowAgainCheckboxChecked)
    );
  }

  private get speedTestSettings(): SpeedTestSettingsModel {
    return {
      file: {
        path: 'assets/images/logos/n-color-red-215x80.svg',
        size: 58112,
        shouldBustCache: true,
      },
    };
  }

  private checkInternetSpeed(speed: number): Observable<boolean> {
    if (speed <= this.minInternetSpeedMbps) {
      return this.showWeakConnectionModal();
    }

    return of(false);
  }

  private showWeakConnectionModal(): Observable<boolean> {
    return this.modalService.openPopupWithCheckboxModal(
      'app.weakConnectionPopup.titleKey',
      'app.weakConnectionPopup.textKey',
      'app.weakConnectionPopup.buttonLabelKey',
      'app.weakConnectionPopup.checkboxLabelKey',
      false
    );
  }

  private showNoConnectionModal(): Observable<boolean> {
    return this.modalService.openPopupModal(
      'app.noConnectionPopup.titleKey',
      'app.noConnectionPopup.textKey',
      'app.noConnectionPopup.successButtonLabelKey',
      undefined,
      false
    );
  }
}
